const users = {
    "monitoramento@sertras.com.br": "sertras_monitoramento",
    "monitoramento@portobello.com.br": "portobello_monitoramento",
    "monitoramento@agcapital.com.br": "agcapital_monitoramento",
    "monitoramento@virtu.com.br": "virtu_monitoramento",
    "monitoramento@lifes.com.br": "lifes_monitoramento",
    "monitoramento@daycoval.com.br": "daycoval_monitoramento",
    "monitoramento@galapagos.com.br": "galapagos_monitoramento",
    "monitoramento@abstrato.com.br": "abstrato_monitoramento",
    "monitoramento@kuaracapital.com.br": "kuaracapital_monitoramento",
    "monitoramento@ibiobi.com.br": "ibiobi_monitoramento",
    "monitoramento@befly.com.br": "befly_monitoramento",
    "gustavotpb@gmail.com": "operacao",
    "jael.selau@kappi.io": "operacao",
    "jefferson.gomes@kappi.io": "operacao",
    "mariany@kredilig.com.br": "KREDILIG",
    "eduarda@kredilig.com.br": "KREDILIG",
    "eduarda.simao@kab.com.br": "KREDILIG",
    "moroni.silva@kab.com.br": "KREDILIG",
    "mariany.eccel@kab.com.br": "KREDILIG",
    "heitor@kdsengenharia.com.br": "KDS",
    "financeiro@kdsengenharia.com.br": "KDS",
    "kds@kdsengenharia.com.br": "KDS",
    "amilcar@kdsengenharia.com.br": "KDS",
    "financeiro01@quantumpar.com.br": "Quantum",
    "toninho@wkoerich.com.br": "WKoerich",
    "rodolfo.porte.partner@vexia.com.br": "vexia",
    "keicy.pereira.partner@vexia.com.br": "vexia",
    "nathalia.almeida@sertras.com": "sertras",
    "rafael.machado@sertras.com": "sertras",
    "ana.barbosa@sertras.com.br": "sertras",
    "palloma.mazzeo@sertras.com": "sertras",
    "rodolfo.sandoval@sertras.com.br": "sertras",
    "lucas.guimaraes@sertras.com.br": "sertras",
    "diego.vargas@sertras.com.br": "sertras",
    "carlos.kappi@sertras.com.br": "sertras",
    "patrick.moraes@sertras.com": "sertras_homologacao",
    "gustavo.ferreira@sertras.com.br": "sertras_homologacao",
    "gabriel.meirelles@sertras.com.br": "sertras_homologacao",
    "kamila.santos@sertras.com.br": "sertras_homologacao",
    "ellen.cristina@sertras.com.br": "sertras_homologacao",
    "adm@edladvocacia.com": "edl",
    "jgsabino@assertif.com.br": "assertif",
    "fernando.sottomaior@smnadv.com.br": "smnadv",
    "victorkoerich@wkoerich.com.br": "WKoerich",
    "contato@bgtadvogados.com.br": "bgtadvogados",
    "victoria@schiefler.adv.br": "schiefler",
    "eduardo@schiefler.adv.br": "schiefler",
    "giovanna@schiefler.adv.br": "schiefler",
    "marco@schiefler.adv.br": "schiefler",
    "laisa@schiefler.adv.br": "schiefler",
    "inova@mprj.mp.br": "mprj",
    "mauro.reyes@abcr.com.br": "ABCR",
    "abner.rosa@abcr.com.br": "ABCR",
    "marlonfaust@valorama.com.br": "valorama",
    "andreia@sabi.adv.br": "SABI",
    "elaine@sabi.adv.br": "SABI",
    "cristiane.diniz@isaac.com.br": "isaac",
    "torben@terrawembalagens.com.br": "terrawembalagens",
    "gustavo@terrawembalagens.com.br": "terrawembalagens",
    "felipe@terrawembalagens.com.br": "terrawembalagens",
    "ricardo@terrawembalagens.com.br": "terrawembalagens",
    "administrativo@konger.com.br": "konger",
    "ncarvalho@pn.com.br": "pinheironeto",
    "dmatos@mestraconsultoria.com": "mestraconsultoria",
    "aevangelista@maestraconsultoria.com.br": "mestraconsultoria",
    "aldo@trackcr.com.br": "trackcr",
    "neiluiz@trackcr.com.br": "trackcr",
    "diego.ehs@quantumpar.com.br": "quantumpar2",
    "ailtonjunior@quantumpar.com.br": "quantumpar2",
    "patricia@quantumpar.com.br": "quantumpar2",
    "diego.ehs@quantumpar.com.br": "quantumpar2",
    "thiago@quantumengenharia.com.br": "quantumpar2",
    "liandra@nazario.adv.br": "quantumpar2",
    "enelita@beiramar.com.br": "beiramar",
    "gefin@beiramar.com.br": "beiramar",
    "luciana@beiramar.com.br": "beiramar",
    "tatiana@beiramar.com.br": "beiramar",
    "agda.rocha@beiramar.com.br": "beiramar",
    "amanda@saadv.com": "saadv",
    "almeidamarina@saadv.com": "saadv",
    "eduardo@saadv.com": "saadv",
    "amanda.rufino@portobello.com.br": "portobello",
    "thiago.luiz@portobello.com.br": "portobello",
    "nattan.silva@portobello.com.br": "portobello",
    "luiz.fernando@portobello.com.br": "portobello",
    "simone.constantino@portobello.com.br": "portobello",
    "ana.pereira@portobello.com.br": "portobello",
    "maryana.lohse@portobello.com.br": "portobello",
    "william@imaconsultoria.com.br": "imaconsultoria",
    "anderson@imaconsultoria.com.br": "imaconsultoria",
    "operacoes@kuaracapital.com": "kuaracapital",
    "juridico@kuaracapital.com": "kuaracapital",
    "compliance@kuaracapital.com": "kuaracapital",
    "carlos.kappi@kuaracapital.com": "kuaracapital",
    "alex@boschirolli.adv.br": "boschirolli",
    "pedro.portes@urbe.me": "urbe",
    "leonardo.malta@urbe.me": "urbe",
    "admin@urbe.me": "urbe",
    "dpoliveira@empirica.com.br": "empirica",
    "abrito@empirica.com.br": "empirica",
    "lboeira@empirica.com.br": "empirica",
    "gmuro@empirica.com.br": "empirica",
    "mesuppi@empirica.com.br": "empirica",
    "contencioso@figueirabertoni.com.br": "figueirabertoni",
    "gabriel.figueira@figueirabertoni.com.br": "figueirabertoni",
    "marcello.bertoni@figueirabertoni.com.br": "figueirabertoni",
    "karine.silva@agcapital.com.br": "agcapital_compliance",
    "gabriel.fachini@agcapital.com.br": "agcapital_compliance",
    "aline.dallagnol@agcapital.com.br": "agcapital_plna_gest",
    "juridico@agcapital.com.br": "agcapital_juridico",
    "paula.bernardes@agcapital.com.br": "agcapital_juridico",
    "gabriela.picinato@agcapital.com.br": "agcapital_juridico",
    "jessica.steiner@agcapital.com.br": "agcapital_juridico",
    "matheus_gomes@bancodigimais.com.br": "bancodigimais",
    "renan_mizoguchi@bancodigimais.com.br": "bancodigimais",
    "jose_rocha@bancodigimais.com.br": "bancodigimais",
    "thiago_urbaneja@bancodigimais.com.br": "bancodigimais",
    "suzi@bshlaw.com.br": "bshlaw",
    "byung@bshlaw.com.br": "bshlaw",
    "bruna@bshlaw.com.br": "bshlaw",
    "alansung@bshlaw.com.br": "bshlaw",
    "patriciamassuno@bshlaw.com.br": "bshlaw",
    "washington.nascimento@bshlaw.com.br": "bshlaw",
    "anderson.silva@bshlaw.com.br": "bshlaw",
    "alvaropupo@bshlaw.com.br": "bshlaw",
    "cadastro@ourominas.com": "omdtvm",
    "henrique@investoetf.com": "investoetf",
    "anapaula.yamasaki@omnisaude.app": "omnisaude",
    "marcelo@contabull.com": "contabull",
    "matheus@contabull.com": "contabull",
    "schulz@contabull.com": "contabull",
    "mauricio@contabull.com": "contabull",
    "eric@contabull.com": "contabull",
    "administrativo@gdr.adv.br": "gdr",
    "administrativo2@gdr.adv.br": "gdr",
    "rodrigo@3structure.com.br": "3structure",
    "marina@3structure.com.br": "3structure",
    "cleber.ribas@3structure.com.br": "3structure",
    "carlos.mello@3structure.com.br": "3structure",
    "beatriz@3structure.com.br": "3structure",
    "renato@3structure.com.br": "3structure_adm",
    "pfrancato@ecclestonassociates.com": "eccleston",
    "mwestphalen@ecclestonassociates.com": "eccleston",
    "mariana.schmitz@dotgroup.com.br": "dotgroup_pessoal_cultura",
    "lidia.assis@dotgroup.com.br": "dotgroup_pessoal_cultura",
    "caroline.fedalto@dotgroup.com.br": "dotgroup_pessoal_cultura",
    "ana.paula@dotgroup.com.br": "dotgroup_pessoal_cultura",
    "guilherme@dotgroup.com.br": "dotgroup_juridico",
    "bruna.benini@dotgroup.com.br": "dotgroup_juridico",
    "ingrid.rosa@dotgroup.com.br": "dotgroup_juridico",
    "thaiane.koehler@dotgroup.com.br": "dotgroup_juridico",
    "luana.souza@dotgroup.com.br": "dotgroup_compras_comercial",
    "julia.araujo@dotgroup.com.br": "dotgroup_compras_comercial",
    "amanda.pacheco@dotgroup.com.br": "dotgroup_compras_comercial",
    "jakson.souza@dotgroup.com.br": "dotgroup_compras_comercial",
    "liana.victor@dotgroup.com.br": "dotgroup_compras_comercial",
    "claudio.machado@dotgroup.com.br": "dotgroup_compras_comercial",
    "andressa.camilo@dotgroup.com.br": "dotgroup_compras_comercial",
    "marcos.piffer@dotgoup.com.br": "dotgroup_compras_comercial",
    "ana.gomes@dotgroup.com.br": "dotgroup_compras_comercial",
    "samantha.prass@dotgroup.com.br": "dotgroup_compras_comercial",
    "ana.duarte@dotgroup.com.br": "dotgroup_compras_comercial",
    "juridico@dotgroup.com.br": "dotgroup_compras_comercial",
    "stephanie.ribeiro@mnadvocacia.com.br": "mnadvocacia",
    "andre@mnadvocacia.com.br": "mnadvocacia",
    "joao@mnadvocacia.com.br": "mnadvocacia",
    "gustavo-schlosser@mnadvocacia.com.br": "mnadvocacia",
    "leticia@mnadvocacia.com.br": "mnadvocacia",
    "ricardo.malagoli@mnadvocacia.com.br": "mnadvocacia",
    "isabela.silva@mnadvocacia.com.br": "mnadvocacia",
    "mauricio@tladv.com.br": "tladv",
    "marcos.melo@partnersclub.net": "partnersclub",
    "jp@partnersclub.net": "partnersclub",
    "clara.nolasco@partnersclub.net": "partnersclub",
    "aline.carvalho@partnersclub.net": "partnersclub",
    "matheus.oliveira@partnersclub.net": "partnersclub",
    "claudio.ikeda@abstrato.ventures": "abstrato",
    "lucas.machado@datamines.com.br": "abstrato",
    "bruno.souza@abstrato.ventures": "abstrato",
    "juanpablo@innovationcenter.com.br": "abstrato",
    "daniel@abstrato.co": "abstrato",
    "jaime@redeivg.org.br": "abstrato",
    "francisco.garcia@gotcha.com.br": "abstrato",
    "joliveira@pxativosjudiciais.com": "px",
    "raiza@pxcredito.com": "px",
    "sjunior@pxativosjudiciais.com.br": "px",
    "renata@pxativosjudiciais.com.br": "px",
    "besteves@pxativosjudiciais.com.br": "px",
    "ntakahashi@pxativosjudiciais.com.br": "px",
    "kpaz@pxativosjudiciais.com.br": "px",
    "glopes@pxativosjudiciais.com.br": "px",
    "pietro@pxativosjudiciais.com.br": "px",
    "imolezini@pxativosjudiciais.com.br": "px",
    "bfranca@pxativosjudiciais.com.br": "px",
    "vassis@pxativosjudiciais.com.br": "px",
    "caio.leal@btgpactual.com": "btgpactual",
    "jose.werneck@btgpactual.com": "btgpactual",
    "laura.freitas@btgpactual.com": "btgpactual",
    "pedro.mayrink@btgpactual.com": "btgpactual",
    "eduardo.lang@btgpactual.com": "btgpactual",
    "leonardo.bonetti@btgpactual.com": "btgpactual",
    "felipe.hasbani@btgpactual.com": "btgpactual",
    "recepcao@mrsadvogados.com": "mrsadvogados",
    "contato@mrsadvogados.com": "mrsadvogados",
    "documentos@mrsadvogados.com": "mrsadvogados",
    "fabssoares@gmail.com": "MKT_GABI",
    "golingabriela@gmail.com": "MKT_GABI",
    "natalia.motoie@renovainvest.com.br": "renovainvest",
    "daniel.araujo@grupoeuro17.com.br": "grupoeuro17",
    "gabriela.reis@grupoeuro17.com.br": "grupoeuro17",
    "camila.vitoria@grupoeuro17.com.br": "grupoeuro17",
    "nilton.pinheiro@grupoeuro17.com.br": "grupoeuro17",
    "fabiano.paulo@grupoeuro17.com.br": "grupoeuro17",
    "jose.eduardo@grupoeuro17.com.br": "grupoeuro17",
    "barbara.lameza@grupoeuro17.com.br": "grupoeuro17",
    "arthur.lima@grupoeuro17.com.br": "grupoeuro17",
    "lais.jobbagy@grupoeuro17.com.br": "grupoeuro17",
    "cybelle.menucci@grupoeuro17.com.br": "grupoeuro17",
    "naiara.manoel@grupoeuro17.com.br": "grupoeuro17",
    "mspconsulting123@outlook.com": "MSP",
    "talita.pereira@neocredit.com.br": "neocredit",
    "robson.garcia@neocredit.com.br": "neocredit",
    "alexandracastro@pbh.gov.br": "pbh",
    "claudia.fusco@pbh.gov.br": "pbh",
    "dgsilva@pbh.gov.br": "pbh",
    "diogoluiz@pbh.gov.br": "pbh",
    "eduardohtc@pbh.gov.br": "pbh",
    "renatak.rezende@pbh.gov.br": "pbh",
    "kappi@pbh.gov.br": "pbh",
    "leticiatrovao@usadv.com.br": "usadv",
    "catarinabogea@usadv.com.br": "usadv",
    "annapaulaalencar@usadv.com.br": "usadv",
    "ulissessousa@usadv.com.br": "usadv",
    "rodrigo.cavalcante@abstratoaceleradora.com": "abstrato_galapagos",
    "andre.papaleo@abstrato.ventures": "abstrato_galapagos",
    "carlos.kappi@galapagos.com": "galapagos_master",
    "jessica.castro@galapagoscapital.com": "galapagos_master",
    "felipe.regis@galapagoscapital.com": "galapagos_master",
    "karina.andrade@galapagoscapital.com": "galapagos_master",
    "mariana.lopes@galapagoscapital.com": "galapagos_compliance",
    "fernanda.sinclair@galapagoscapital.com": "galapagos_compliance",
    "cleide.favero@galapagostaler.com": "galapagos_compliance",
    "gabriel.parente@galapagoscapital.com": "galapagos_juridico",
    "gustavo.mazuca@galapagoscapital.com": "galapagos_juridico",
    "pedro.fonte@galapagoscapital.com": "galapagos_juridico",
    "bruna.souto@galapagoscapital.com": "galapagos_juridico",
    "gabriel.lange@galapagoscapital.com": "galapagos_juridico",
    "guilherme.lee@galapagoscapital.com": "galapagos_juridico",
    "gustavo.holck@galapagoscapital.com": "galapagos_juridico",
    "joao.modesto@galapagoscapital.com": "galapagos_juridico",
    "naomi.wachsmann@galapagoscapital.com": "galapagos_juridico",
    "rafael.silva@galapagoscapital.com": "galapagos_juridico",
    "lucaswc@galapagoscypress.com": "galapagos_cypress",
    "hugo.faro@galapagoscypress.com": "galapagos_cypress",
    "felipe.meirelles@galapagoscypress.com": "galapagos_cypress",
    "matheus.liberato@galapagoscypress.com": "galapagos_cypress",
    "giovanna.steinberg@galapagoscypress.com": "galapagos_cypress",
    "mhiran@galapagoscypress.com": "galapagos_cypress",
    "luca.yunes@galapagoscypress.com": "galapagos_cypress",
    "vitor.riccetti@galapagoscypress.com": "galapagos_cypress",
    "pedro.matos@galapagoscapital.com": "galapagos_cypress",
    "fellipe.castro@galapagoscapital.com": "galapagos_home_equity",
    "fernanda.peronico@galapagoscapital.com": "galapagos_home_equity",
    "ana.mazza@santacruzcapital.com.br": "galapagos_santa_cruz",
    "vanessa.leonardo@galapagoscapital.com": "galapagos_compras",
    "duda.dreux@galapagoscapital.com": "galapagos_people",
    "raphael.siqueira@galapagoscapital.com": "galapagos_people",
    "rebeca.nevares@galapagoscapital.com": "galapagos_marketing",
    "caroline.favero@galapagoscapital.com": "galapagos_marketing",
    "maria.mathias@galapagoscapital.com": "galapagos_operacoes",
    "pedro.leite@galapagoscapital.com": "galapagos_operacoes",
    "arthur.cruvinel@galaticoscapital.com": "galapagos_galaticos",
    "viviane.leal@galaticoscapital.com": "galapagos_galaticos",
    "matheus.tripoli@galaticoscapital.com": "galapagos_galaticos",
    "rodrigo.fonseca@galapagoscapital.com": "galapagos_asset_liquidos",
    "yuri.rettore@galapagoscapital.com": "galapagos_asset_liquidos",
    "eduardo.cotrim@galapagoscapital.com": "galapagos_asset_liquidos",
    "brenno.ramalho@galapagoscapital.com": "galapagos_asset_liquidos",
    "c.leonel@coimbrachaves.com.br": "coimbrachaves",
    "c.campos@coimbrachaves.com.br": "coimbrachaves",
    "pamela.denanni@carbigdata.com.br": "carbigdata",
    "patricia.rocha@carbigdata.com.br": "carbigdata",
    "pedro@carbigdata.com.br": "carbigdata",
    "paulo.andrade@carbigdata.com.br": "carbigdata",
    "victor.silva@carbigdata.com.br": "carbigdata",
}

const session = {
    getUser: (user) => {
        console.log(">>>>>>>>>>user>>>>>>>>>", user)
        console.log(">>>>>>>>>>cliente>>>>>>>>>", users[user.toLowerCase()])
        return users[user.toLowerCase()]
    }
}

module.exports = session;
